import { useNotification } from '@base/composables/useNotifications'
import { useCart } from '@ecom/composables/checkout/useCart/useCart'
import type { NotificationComponentType } from '@base/stores/notifications.types'
import { useCartQueries } from '../useCartQueries'

interface CustomerCartCodesPendingState {
  customerCode: boolean
  couponCode: boolean
}

interface CustomerCartCodesErrorsState {
  customerCode: Error | string | null | unknown
  couponCode: Error | string | null | unknown
}

function useCartCustomerCodes() {
  const { $i18n } = useNuxtApp()
  const { RawlplugAttributesDefaults, cart, getCart, setCart } = useCart()
  const { t } = $i18n
  const pending = ref<CustomerCartCodesPendingState>({
    customerCode: false,
    couponCode: false,
  })
  const errorState = ref<CustomerCartCodesErrorsState>({
    customerCode: null,
    couponCode: null,
  })

  const individualCustomerNumber = ref('')
  const couponCode = ref('')

  const applyCustomerOrderNumberNotificationType
    = 'toast' as NotificationComponentType

  const { showNotification: showToast } = useNotification(
    applyCustomerOrderNumberNotificationType,
  )

  const isIndividualCustomerNumberAssigned = computed(() => Boolean(cart.value?.customer_order_nr ?? false))
  const isCouponCodeApplied = computed(() => Boolean(cart.value?.applied_coupon?.code ?? false))

  /**
   * Method used for setting customer's individual order code on cart
   * @param {bolean} [showNotification]
   */
  const setCustomerOrderNumber = async (
    showNotification = true,
  ) => {
    if (!cart.value?.id) {
      return
    }

    if (cart.value?.customer_order_nr === individualCustomerNumber.value) {
      return
    }

    pending.value.customerCode = true
    errorState.value.customerCode = null

    try {
      const { setCustomerOrderNumberOnCart } = useCartQueries()

      const cartResponse = await setCustomerOrderNumberOnCart({
        cartId: cart.value.id,
        customerOrderNr: individualCustomerNumber.value,
        attributes: RawlplugAttributesDefaults,
      })
      setCart(cartResponse)
      const { customer_order_nr: responseOrderNumber } = cartResponse

      if (showNotification) {
        showToast({
          id: crypto.randomUUID(),
          type: 'success',
          message: t('ecom_your_order_number_was_successfully_assigned'),
          closable: false,
          timeout: 3000,
        })
      }

      if (responseOrderNumber) {
        individualCustomerNumber.value = responseOrderNumber
      }

      return responseOrderNumber ?? ''
    }
    catch (error) {
      errorState.value.customerCode = error
    }
    finally {
      pending.value.customerCode = false
    }
  }

  async function clearCustomerIndividualOrderNumber() {
    individualCustomerNumber.value = ''
    await setCustomerOrderNumber()
  }

  const applyCoupon = async (
    showNotification = true,
  ) => {
    if (!cart.value?.id) {
      return
    }

    if (cart.value?.applied_coupon?.code === couponCode.value) {
      return
    }

    pending.value.couponCode = true
    errorState.value.couponCode = null

    try {
      const { applyCouponToCart } = useCartQueries()

      const response = await applyCouponToCart({
        input: {
          cart_id: cart.value.id,
          coupon_code: couponCode.value.trim(),
        },
      })

      if (response) {
        await getCart()
        if (showNotification) {
          showToast({
            id: crypto.randomUUID(),
            type: 'success',
            message: t('ecom_coupon_code_was_successfully_assigned'),
            closable: false,
            timeout: 3000,
          })
        }
      }

      return response
    }
    catch (error) {
      errorState.value.couponCode = error
    }
    finally {
      pending.value.couponCode = false
    }
  }

  const removeCoupon = async (
    showNotification = true,
  ) => {
    if (!cart.value?.id) {
      return
    }

    pending.value.couponCode = true
    errorState.value.couponCode = null

    try {
      const { removeCouponFromCart } = useCartQueries()

      const response = await removeCouponFromCart({
        input: {
          cart_id: cart.value.id,
        },
      })

      if (response?.cart?.applied_coupon == null) {
        couponCode.value = ''

        await getCart()

        if (showNotification) {
          showToast({
            id: crypto.randomUUID(),
            type: 'success',
            message: t('ecom_coupon_code_was_successfully_removed'),
            closable: false,
            timeout: 3000,
          })
        }
      }

      return response
    }
    catch (error) {
      errorState.value.couponCode = error
    }
    finally {
      pending.value.couponCode = false
    }
  }

  function init() {
    individualCustomerNumber.value = cart.value?.customer_order_nr ?? ''
    couponCode.value = cart.value?.applied_coupon?.code ?? ''
  }

  onBeforeMount(() => {
    init()
  })

  return {
    individualCustomerNumber,
    couponCode,
    setCustomerOrderNumber,
    clearCustomerIndividualOrderNumber,
    applyCoupon,
    removeCoupon,
    isIndividualCustomerNumberAssigned,
    isCouponCodeApplied,
    pending,
    errorState,
  }
}

export { useCartCustomerCodes }
