import type {
  AddToCartMutationVariables,
  ApplyCouponToCartMutationVariables,
  CustomerCartQueryVariables,
  GetBluemediaRedirectPaymentUrlQueryVariables,
  PlaceOrderMutationVariables,
  ProductCheckerQueryVariables,
  RemoveCouponFromCartMutationVariables,
  RemoveItemsFromCartMutationVariables,
  SetBillingAddressOnCartMutationVariables,
  SetCartDivisionMethodOnCartMutationVariables,
  SetCustomerOrderCommentOnCartMutationVariables,
  SetCustomerOrderNumberOnCartMutationVariables,
  SetPaymentMethodOnCartMutationVariables,
  SetShippingAddressesOnCartMutationVariables,
  SetShippingMethodsOnCartMutationVariables,
  UpdateCartItemsMutationVariables,
} from '#gql'

function useCartQueries() {
  /**
   * The customerCart returns the active cart for the logged-in customer.
   * If the cart does not exist, the query creates one.
   */
  const getCustomerCart = async (variables: CustomerCartQueryVariables) =>
    await GqlCustomerCart(variables)

  /**
   * Adds simple product to cart
   */
  const addProductsToCart = async (variables: AddToCartMutationVariables) => {
    const { addProductsToCart } = await GqlAddToCart(variables)
    return addProductsToCart
  }

  // updates cart items
  const updateCartItems = async (
    variables: UpdateCartItemsMutationVariables,
  ) => {
    const { updateCartItems } = await GqlUpdateCartItems(variables)
    return updateCartItems
  }

  const removeItemsFromCart = async (
    variables: RemoveItemsFromCartMutationVariables,
  ) => {
    const { removeItemsFromCart } = await GqlRemoveItemsFromCart(variables)
    return removeItemsFromCart
  }

  const getCheckoutAgreements = async () => {
    const { checkoutAgreements } = await GqlCheckoutAgreements()
    return checkoutAgreements
  }

  const setShippingAddressesOnCart = async (
    variables: SetShippingAddressesOnCartMutationVariables,
  ) => {
    const { setShippingAddressesOnCart }
      = await GqlSetShippingAddressesOnCart(variables)
    return setShippingAddressesOnCart
  }

  const setBillingAddressOnCart = async (
    variables: SetBillingAddressOnCartMutationVariables,
  ) => {
    const { setBillingAddressOnCart }
      = await GqlSetBillingAddressOnCart(variables)
    return setBillingAddressOnCart
  }

  const setShippingMethodsOnCart = async (
    variables: SetShippingMethodsOnCartMutationVariables,
  ) => {
    const { setShippingMethodsOnCart }
      = await GqlSetShippingMethodsOnCart(variables)
    return setShippingMethodsOnCart
  }

  const setPaymentMethodOnCart = async (
    variables: SetPaymentMethodOnCartMutationVariables,
  ) => {
    const { setPaymentMethodOnCart }
      = await GqlSetPaymentMethodOnCart(variables)
    return setPaymentMethodOnCart
  }

  const setOrderCommentOnCart = async (
    variables: SetCustomerOrderCommentOnCartMutationVariables,
  ) => {
    const { setCustomerOrderComment }
      = await GqlSetCustomerOrderCommentOnCart(variables)
    return setCustomerOrderComment
  }

  const applyCouponToCart = async (
    variables: ApplyCouponToCartMutationVariables,
  ) => {
    const { applyCouponToCart }
      = await GqlApplyCouponToCart(variables)
    return applyCouponToCart
  }

  const removeCouponFromCart = async (
    variables: RemoveCouponFromCartMutationVariables,
  ) => {
    const { removeCouponFromCart }
      = await GqlRemoveCouponFromCart(variables)
    return removeCouponFromCart
  }

  const setProductStockExceededOptionOnCart = async (
    variables: SetCartDivisionMethodOnCartMutationVariables,
  ) => {
    const { setSplitMethodOnCart }
      = await GqlSetCartDivisionMethodOnCart(variables)

    return setSplitMethodOnCart
  }

  const setCustomerOrderNumberOnCart = async (
    variables: SetCustomerOrderNumberOnCartMutationVariables,
  ) => {
    const { setCustomerOrderNumber }
      = await GqlSetCustomerOrderNumberOnCart(variables)
    return setCustomerOrderNumber
  }

  const getBluemediaRedirectPaymentUrl = async (
    variables: GetBluemediaRedirectPaymentUrlQueryVariables,
  ) => {
    const { redirectUrl }
      = (await GqlGetBluemediaRedirectPaymentUrl(variables)) || {}

    return redirectUrl
  }

  const placeOrder = async (variables: PlaceOrderMutationVariables) => {
    const { placeOrder } = await GqlPlaceOrder(variables)
    return placeOrder
  }

  const productChecker = async (variables: ProductCheckerQueryVariables) =>
    await GqlProductChecker(variables)

  return {
    getCustomerCart,
    addProductsToCart,
    updateCartItems,
    removeItemsFromCart,
    getCheckoutAgreements,
    setShippingAddressesOnCart,
    setBillingAddressOnCart,
    setShippingMethodsOnCart,
    setPaymentMethodOnCart,
    setOrderCommentOnCart,
    setProductStockExceededOptionOnCart,
    setCustomerOrderNumberOnCart,
    applyCouponToCart,
    removeCouponFromCart,
    getBluemediaRedirectPaymentUrl,
    placeOrder,
    productChecker,
  }
}
export { useCartQueries }
