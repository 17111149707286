import { useCart } from '@ecom/composables/checkout/useCart/useCart'
import { useCartProductData } from '@ecom/composables/checkout/useCartProductData/useCartProductData'
import type { CustomerCart } from '@ecom/types/types'
import { useCartQueries } from '../useCartQueries'
import type {
  CustomerCartShippingErrors,
  FormattedCustomerCartShippingAddress,
} from './useCartShipping.types'
import type {
  AvailableShippingMethod,
  CustomerDataQuery,
  SetShippingAddressesOnCartInput,
  SetShippingMethodsOnCartInput,
  ShippingAddressInput,
} from '#gql'

function useCartShipping() {
  const { setCart } = useCart()
  const { getCartBeforeOrderSummary } = useCartProductData()
  const pending = ref<boolean>(false)
  const errorState = ref<CustomerCartShippingErrors>({
    load: null,
    save: null,
  })

  const userAddresses = ref<NonNullable<CustomerDataQuery['customer']>['addresses']>([])

  const formattedUserAddresses = computed(
    (): FormattedCustomerCartShippingAddress[] => {
      return (userAddresses.value ?? []).map((address) => {
        const {
          firstname,
          lastname,
          company = '',
          city,
          postcode,
          email,
          id,
          country_code,
          default_shipping,
          default_billing,
        } = address || {}
        const [street = '', number = ''] = address?.street ?? []
        return {
          id,
          email,
          fullname: `${firstname} ${lastname}`,
          company,
          postcode,
          city,
          street,
          number,
          country_code,
          isDefaultShipping: default_shipping,
          isDefaultBilling: default_billing,
        }
      })
    },
  )

  /**
   * Gets customer addresses
   */
  const getShippingAddresses = async () => {
    pending.value = true
    errorState.value.load = null
    try {
      const { getCustomerData } = useCustomerData()
      const { customer = null } = await getCustomerData() || {}

      if (customer?.addresses) {
        userAddresses.value = customer.addresses
      }
    }
    catch (error) {
      errorState.value.save = error
    }
    finally {
      pending.value = false
    }
  }

  /**
   * Internal method for setting shipping address on user's cart
   * @param {string} cartId
   * @param {ShippingAddressInput} input
   */
  const _setShippingAddress = async (
    cartId: string,
    input: ShippingAddressInput,
  ) => {
    if (!cartId || !input) {
      return
    }

    pending.value = true
    errorState.value.save = null

    try {
      const { setShippingAddressesOnCart } = useCartQueries()
      const builtInput = createSetShippingAddressesOnCartInput(cartId, input)

      if (!builtInput) {
        throw new Error(
          'SetShippingAddressesOnCartInput was not provided correctly',
        )
      }

      const updatedCart = await setShippingAddressesOnCart({
        input: builtInput,
      })
      return updatedCart
    }
    catch (error) {
      const { $parseGqlError } = useNuxtApp()
      errorState.value.save = $parseGqlError(error)
    }
    finally {
      pending.value = false
    }
  }
  /**
   * Method used for setting shipping address on user's cart. Internally uses _setShippingAddress
   * @param {string} cartId
   * @param {ShippingAddressInput} input
   */
  const setShippingAddress = async (
    cartId: string,
    input: ShippingAddressInput,
  ) => {
    if (!cartId || !input) {
      return
    }

    const { cart = null } = (await _setShippingAddress(cartId, input)) || {}
    setCart(cart as CustomerCart)

    return cart?.shipping_addresses?.[0] ?? null
  }

  /**
   * Method used for creating payload for setting shipping address on user's cart
   * @param {string} cartId
   * @param {ShippingAddressInput} input
   */
  const createSetShippingAddressesOnCartInput = (
    cartId: string,
    input: ShippingAddressInput,
  ): SetShippingAddressesOnCartInput | null => {
    if (!cartId || !input) {
      return null
    }

    return {
      cart_id: cartId,
      shipping_addresses: [
        {
          ...input,
        },
      ],
    }
  }

  /**
   * Internal method for setting shipping method on user's cart
   * @param {string} cartId
   * @param {AvailableShippingMethod} method
   */
  const _setShippingMethod = async (
    cartId: string,
    method: AvailableShippingMethod,
  ) => {
    if (!cartId || !method) {
      return
    }

    pending.value = true
    errorState.value.save = null

    try {
      const { setShippingMethodsOnCart } = useCartQueries()

      const input = createSetShippingMethodsOnCartInput(cartId, method)

      if (!input) {
        throw new Error(
          'SetShippingMethodsOnCartInput was not provided correctly',
        )
      }

      const updatedCart = await setShippingMethodsOnCart({
        input,
      })
      return updatedCart
    }
    catch (error) {
      const { $parseGqlError } = useNuxtApp()
      errorState.value.save = $parseGqlError(error)
    }
    finally {
      pending.value = false
    }
  }

  /**
   * Method used for setting shipping method on user's cart. Internally uses _setShippingMethod
   * @param {string} cartId
   * @param {AvailableShippingMethod} method
   */
  const setShippingMethod = async (
    cartId: string,
    method: AvailableShippingMethod,
  ) => {
    if (!cartId || !method) {
      return
    }

    const { cart = null } = (await _setShippingMethod(cartId, method)) || {}
    setCart(cart as CustomerCart)

    const { shipping_addresses: responseShippingAddresses } = cart || {}
    return (
      responseShippingAddresses?.[0]?.selected_shipping_method ?? null ?? null
    )
  }

  /**
   * Method used for creating payload for setting shipping method on user's cart
   * @param {string} cartId
   * @param {AvailableShippingMethod} method
   */
  const createSetShippingMethodsOnCartInput = (
    cartId: string,
    method: AvailableShippingMethod,
  ): SetShippingMethodsOnCartInput | null => {
    if (!cartId || !method) {
      return null
    }

    const { carrier_code, method_code } = method

    if (!carrier_code || !method_code) {
      return null
    }

    return {
      cart_id: cartId,
      shipping_methods: [
        {
          carrier_code,
          method_code,
        },
      ],
    }
  }

  /**
   * Method used for finding default customer's shipping address
   */
  const findDefaultShippingAddress = () =>
    userAddresses.value?.find(address => address?.default_shipping) ?? null

  /**
   * Method used for finding default customer's shipping address by it's id
   */
  const findShippingAddressById = (id: string | number) => {
    const internalId = typeof id === 'string' ? Number(id) : id
    return (
      userAddresses.value?.find(address => address?.id === internalId) ?? null
    )
  }

  const _calculateShippingInformationValues = (
    amountToFreeShipping: number | null,
  ) => ({
    isFreeShipping:
      typeof amountToFreeShipping === 'number' && amountToFreeShipping <= 0,
    remainingValueForFreeShipping:
      typeof amountToFreeShipping === 'number' && amountToFreeShipping > 0
        ? amountToFreeShipping
        : null,
  })

  const getShippingInformationForAvailableProducts = (cart: CustomerCart) => {
    if (!cart) {
      return null
    }
    const { splitData } = getCartBeforeOrderSummary(cart)

    const { availableShippingCost, availableAmountToFreeShipping } = splitData

    const areAvailableProductsInCart = availableShippingCost !== null

    if (!areAvailableProductsInCart) {
      return null
    }

    return _calculateShippingInformationValues(availableAmountToFreeShipping)
  }

  const getShippingInformationForUnavailableProducts = (
    cart: CustomerCart,
  ) => {
    if (!cart) {
      return null
    }
    const { splitData } = getCartBeforeOrderSummary(cart)

    const { notAvailableShippingCost, notAvailableAmountToFreeShipping }
      = splitData

    const areNotAvailableProductsInCart = notAvailableShippingCost !== null

    if (!areNotAvailableProductsInCart) {
      return null
    }

    return _calculateShippingInformationValues(notAvailableAmountToFreeShipping)
  }

  const getShippingInformationWhenCartDivisionDisabled = (
    cart: CustomerCart,
  ) => {
    if (!cart) {
      return null
    }

    const { subtotalWithDiscountExcludingTax } = getCartBeforeOrderSummary(cart)

    if (!subtotalWithDiscountExcludingTax?.value) {
      return null
    }

    const FREE_DELIVERY_MINIMUM_VALUE = 700

    const howMuchRemain
      = FREE_DELIVERY_MINIMUM_VALUE - subtotalWithDiscountExcludingTax.value

    return _calculateShippingInformationValues(howMuchRemain)
  }

  const getShippingInformation = (cart: CustomerCart) => {
    if (!cart) {
      return null
    }

    return {
      availableProducts: getShippingInformationForAvailableProducts(cart),
      notAvailableProducts: getShippingInformationForUnavailableProducts(cart),
      cartDivisionDisabled:
        getShippingInformationWhenCartDivisionDisabled(cart),
    }
  }

  return {
    getShippingAddresses,
    setShippingAddress,
    findDefaultShippingAddress,
    findShippingAddressById,
    setShippingMethod,
    getShippingInformationForAvailableProducts,
    getShippingInformationForUnavailableProducts,
    getShippingInformationWhenCartDivisionDisabled,
    getShippingInformation,
    addresses: userAddresses,
    formattedAddresses: formattedUserAddresses,
    pending: readonly(pending),
    error: readonly(errorState),
  }
}

export { useCartShipping }
