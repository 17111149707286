export async function retry<T>(
  fn: () => Promise<T>,
  retries: number,
  delay?: number,
): Promise<T | undefined> {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn()
    }
    catch (error) {
      if (i < retries - 1 && delay) {
        await new Promise(resolve => setTimeout(resolve, delay))
      }
      else {
        throw error
      }
    }
  }
}
