import type { AddToCartMutationVariables, Cart, UpdateCartItemsMutationVariables } from '#gql'

export interface CustomerCartErrors {
  load: Error | string | null | unknown
  addItem: Error | string | null | unknown
  updateItem: Error | string | null | unknown
  loadAgreements: Error | string | null | unknown
  placeOrder: Error | string | null | unknown
}

export interface CartStoreState {
  cart: Cart | null
  pending: boolean
  loadingItems: string[]
  errorState: CustomerCartErrors
}

export interface ProductAddableToCart {
  sku?: string | null
  uid?: string | null
  __typename?: string
  stock_status?: string | null
  only_x_left_in_stock?: number | null
  erp_status?: string | null
}

export interface AddToCartOverload {
  (params: Omit<AddToCartMutationVariables, 'cartId'>): void
  (params: AddToCartMutationVariables): void
}

export interface UpdateCartItemsOverload {
  (params: Omit<UpdateCartItemsMutationVariables, 'cartId'>): void
  (params: UpdateCartItemsMutationVariables): void
}
export const NO_CART_ID = 'no-cart-id'
