import type { CustomerCart } from '../types/types'
import type { AvailableShippingMethod, SelectedShippingMethod, ShippingCartAddress } from '#gql'

export function findSingleProductInCartByUid(cart: CustomerCart, uid: string) {
  return cart?.items?.find(item => item?.uid === uid) ?? null
}

export function findProductsInCartByUid(cart: CustomerCart, uidList: string[]) {
  if (!uidList || !Array.isArray(uidList)) { return }
  return cart?.items?.filter(item => uidList.includes(item?.uid ?? '')) ?? []
}

export function findProductsInCartBySkus(cart: CustomerCart, skusList: string[]) {
  if (!skusList || !Array.isArray(skusList)) { return }
  return (
    cart?.items?.filter(item => skusList.includes(item?.product?.sku ?? ''))
    ?? []
  )
}

export function getAvailableShippingMethods(cartShippingAddresses: ShippingCartAddress[]): undefined | AvailableShippingMethod[] {
  if (!cartShippingAddresses || !Array.isArray(cartShippingAddresses)) {
    return
  }

  return (cartShippingAddresses ?? []).reduce(
    (acc: ShippingCartAddress[], actual) =>
      acc.concat(actual.available_shipping_methods || []),
    [],
  ) as unknown as AvailableShippingMethod[]
}

export function getSelectedShippingMethods(cartShippingAddresses: ShippingCartAddress[]): SelectedShippingMethod[] {
  if (!cartShippingAddresses || !Array.isArray(cartShippingAddresses)) {
    return []
  }

  const selectedShippingMethods = [] as SelectedShippingMethod[]

  cartShippingAddresses?.forEach((address) => {
    if (address?.selected_shipping_method) {
      selectedShippingMethods.push(address.selected_shipping_method)
    }
  })

  return selectedShippingMethods
}

export function findShippingMethodByCode(cartShippingAddresses: ShippingCartAddress[], code: string) {
  const methods = getAvailableShippingMethods(cartShippingAddresses)

  return (
    methods?.find(shippingMethod => shippingMethod.method_code === code)
    ?? null
  )
}
