import { storeToRefs } from 'pinia'
import { useCartStore } from '@ecom/stores/cart'

/**
 * Aggregates and exposes cart's store data
 */
function useCart() {
  const customerCartStore = useCartStore()

  const {
    cart,
    cartItemsCount,
    cartItemsProductsCategories,
    cartItemsWithProductQuantityDataChanged,
    cartShippingInformation,
    loadingItems,
    pending,
    errors,
    loadingLayer,
    cartDivisionMethod,
    onlyVirtualProductsInCart,
  } = storeToRefs(customerCartStore)
  const {
    getCart,
    addToCart,
    addToCartMoreItems,
    updateCartItems,
    removeCartItems,
    placeOrder,
    canAddToCart,
    shouldItemBeDisabled,
    showCartNotification,
    findSingleProductInCartByUid,
    findProductsInCartByUid,
    findProductsInCartBySkus,
    setCart,
    setCartDivisionMethod,
    showLoadingLayer,
    RawlplugAttributesDefaults,
    resetCartDivisionMethod,
    resetCartStore,
  } = customerCartStore

  return {
    cart,
    cartItemsCount: readonly(cartItemsCount),
    cartItemsProductsCategories,
    cartItemsWithProductQuantityDataChanged,
    cartShippingInformation,
    getCart,
    addToCart,
    addToCartMoreItems,
    updateCartItems,
    removeCartItems,
    findSingleProductInCartByUid,
    findProductsInCartByUid,
    findProductsInCartBySkus,
    canAddToCart,
    shouldItemBeDisabled,
    showCartNotification,
    placeOrder,
    setCart,
    cartDivisionMethod,
    setCartDivisionMethod,
    RawlplugAttributesDefaults,
    loadingItems: readonly(loadingItems),
    pending: readonly(pending),
    showLoadingLayer,
    loadingLayer: readonly(loadingLayer),
    errors,
    resetCartDivisionMethod,
    resetCartStore,
    onlyVirtualProductsInCart,
  }
}

export { useCart }
